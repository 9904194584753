import clsx from "clsx";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { duplicate, pause } from "../Utils";
import "../styles/layout.css";

/** @type {ForwardRef<ScrollListProps, HTMLDivElement>} */
const ScrollList = forwardRef((props, ref) => {
  const {
    className,
    direction,
    borderRadius,
    background,
    autoHide = "move",
    style,
    showEdge,
    children,
    defer,
  } = props;

  const [scrollList, setScrollList] = useState(null);
  const [edgeOpacity, setEdgeOpacity] = useState(0);
  const [container, setContainer] = useState(null);
  const [loaded, setLoaded] = useState(false);

  // prettier-ignore
  useImperativeHandle(ref,() => {
    return scrollList;
  }, [children]);

  useEffect(() => {
    if (!container) return;
    animateCards(container);
    pause(300).then(() => checkEdgeOnScroll(container));
  }, [direction, children, container]);

  function checkEdgeOnScroll(container) {
    if (!container || !container.childNodes.length) return;

    if (direction === "vertical") {
      verticalEdgeOpacity(container);
    } else {
      horizontalEdgeOpacity(container);
    }
  }

  /** @param {Element} container */
  function verticalEdgeOpacity(container) {
    const content = container.parentElement;
    const overflowHeight = content.scrollHeight - content.offsetHeight;
    const scrollBottom = overflowHeight - parseInt(content.scrollTop);
    const cardHeight = container.lastElementChild.offsetHeight;
    const tallerThanCard = content.clientHeight > 1.2 * cardHeight;
    setEdgeOpacity(scrollBottom > 5 && tallerThanCard ? 1 : 0);
  }

  /** @param {Element} container */
  function horizontalEdgeOpacity(container) {
    const content = container.parentElement;
    const overflowWidth = content.scrollWidth - content.offsetWidth;
    const scrollRight = overflowWidth - parseInt(content.scrollLeft);
    setEdgeOpacity(scrollRight > 5 ? 1 : 0);
  }

  /** @param {Element} container */
  async function animateCards(container) {
    await pause(200);
    setLoaded(true);
    for (const card of Array.from(container.children)) {
      card.style.opacity = 1;
      card.classList.add("visible");
      await pause(20);
    }
  }

  return (
    <OverlayScrollbarsComponent
      className={clsx("scroll-list", className, direction)}
      options={{ scrollbars: { autoHide } }}
      events={{
        scroll: () => checkEdgeOnScroll(container),
        initialized: (inst) => setScrollList(inst.elements().viewport),
      }}
      style={{
        "--list-border-radius": borderRadius,
        "--list-bg-color": background,
        opacity: loaded ? 1 : 0,
        ...style,
      }}
      defer={defer}
    >
      <div className="rounded-mask" />
      <div className="list-container" ref={setContainer}>
        {children}
      </div>
      {showEdge && (
        <span
          className={clsx("scroll-list-edge", direction)}
          style={{ opacity: edgeOpacity }}
        >
          {duplicate(<div />, 5)}
        </span>
      )}
    </OverlayScrollbarsComponent>
  );
});

export default ScrollList;
