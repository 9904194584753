import DashContainer from "@src/components/DashContainer";
import {
  IconArrowRight,
  IconCheck,
  IconPlayerPause,
  IconSignRight,
} from "@tabler/icons-react";
import axios from "axios";
import chroma from "chroma-js";
import { clsx } from "clsx";
import forge from "node-forge";
import { ClickScrollPlugin, OverlayScrollbars } from "overlayscrollbars";
import { useContext, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ServerUrl,
  calculateTestingProgress,
  calculateTrainingProgress,
  defaults,
  fetchTestingScore,
  getActivityName,
  getCssVar,
  getModuleName,
  getScenarioName,
  getScenarioType,
  getSubset,
  getTaskName,
  isFirstActivityInTrainingModule,
  isModulePauseEnd,
  loadFor,
  pause,
} from "../Utils";
import { Button } from "../components/Button";
import ButtonToggle from "../components/ButtonToggle";
import Card from "../components/Card";
import { InfoTooltip } from "../components/ProfileModal";
import {
  CircularProgressBar,
  ContinuousProgressBar,
} from "../components/ProgressBar";
import { SVG } from "../components/SVG";
import ScrollList from "../components/ScrollList";
import TrainingPausedModal from "../components/TrainingPausedModal";
import { DisplayInfo } from "../contexts/Contexts";
import "../styles/dashboard.css";

function Dashboard() {
  const { isTablet, isMobile, isPortrait } = useContext(DisplayInfo);
  const [overallProgress, setOverallProgress] = useState(null);
  const [activityState, setActivityState] = useState({});
  const [cardListType, setCardlistType] = useState("up-next");
  const [mainCardBG, setMainCardBG] = useState("var(--disabled-card-bg-color)");
  const [cardList, setCardList] = useState(cardListPlaceHolder());
  const [changingTask, setChangingTask] = useState(false);
  const [isModulePaused, setIsModulePaused] = useState(false);
  const [isPausedOpen, setIsPausedOpen] = useState(false);
  const mainCardBtnRef = useRef(null);
  const modulesListRef = useRef(null);
  const pauseTimerRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [userInfo, setUserInfo] = useState(location.state);

  const isPortraitMobile = isMobile && isPortrait;

  const tinyMobile = document.documentElement.clientWidth <= 330;
  const smallTablet = document.documentElement.clientWidth <= 530;
  const truncListTitle = tinyMobile || (!isPortraitMobile && smallTablet);

  const tests = ["preTest", "postTest", "postDrivingTest"];

  OverlayScrollbars.plugin(ClickScrollPlugin);
  document.body.id = "dashboard";

  useEffect(() => {
    if (!userInfo) {
      axios
        .post(
          `${ServerUrl}/users/current-user`,
          {},
          {
            withCredentials: true,
          },
        )
        .then((res) => {
          const md = forge.md.sha256.create();
          md.update(res.data.data.email);

          setUserInfo({
            ...getSubset(res.data.data, defaults.dashboardState),
            emailHash: md.digest().toHex(),
          });
        })
        .catch((err) => console.log(err));
    }
  }, []);

  useEffect(() => {
    fetchActivityInfo();
  }, [userInfo]);

  useEffect(() => {
    // training pause between modules
    if (
      userInfo &&
      userInfo.inPerson &&
      activityState.taskId &&
      !activityState.done &&
      isFirstActivityInTrainingModule(activityState)
    ) {
      const pauseOverKey = `${userInfo.emailHash}-${activityState.moduleId}-pauseOver`;

      const pauseOver = localStorage.getItem(pauseOverKey);
      if (pauseOver !== null && pauseOver === "true") {
        setIsModulePaused(false);
        return;
      }

      const startTimeKey = `${userInfo.emailHash}-${activityState.moduleId}-startTime`;

      let startTime = localStorage.getItem(startTimeKey);
      if (startTime === null) {
        startTime = Date.now();
        localStorage.setItem(startTimeKey, startTime);
      }

      setIsModulePaused(true);
      pauseTimerRef.current = setInterval(() => {
        const modulePauseEnd = isModulePauseEnd(startTime);
        if (modulePauseEnd) {
          setIsModulePaused(false);
          return clearInterval(pauseTimerRef.current);
        }
        setIsModulePaused(true); // required?
      }, 1000);

      return () => {
        clearInterval(pauseTimerRef.current);
      };
    }
  }, [activityState]);

  useEffect(() => {
    const taskId = activityState.taskId;
    const postTrainingTasks = ["postTest", "postDrivingTest"];
    const tasks = ["preTest", "training", ...postTrainingTasks];
    const trainDone = taskId === "training" && activityState.progress === 100;

    if (tasks.includes(taskId)) {
      loadCards(postTrainingTasks.includes(taskId) || trainDone);
    }
  }, [cardListType, activityState]);

  async function fetchActivityInfo() {
    if (!userInfo || !userInfo.participantId) return;

    await loadFor(1000, async () => {
      const res = await axios.get(
        `${ServerUrl}/users/${userInfo.participantId}/current-activity`,
        { withCredentials: true },
      );
      return parseActivityInfo(res);
    })
      .then(({ overallProgress, ...parsedActivityState }) => {
        setActivityState(parsedActivityState);
        setOverallProgress(overallProgress);
      })
      .catch((err) => {
        if (err.response.status === 404) {
          // TODO: why ? display server down?
          setActivityState({
            taskId: "training",
            moduleId: "module_1",
            scenarioId: "intersections_1",
            activityId: "S",
            practiceMode: false,
            assetPath: "module_1/intersections_1/S",
            type: "HA",
            progress: 100,
          });
        }
      });
  }

  async function parseActivityInfo(res) {
    const {
      taskId,
      moduleId,
      scenarioId,
      activityId,
      practiceMode,
      assetPath,
      type,
      isCompleted,
      done, // TODO: remove later
    } = res.data.data;

    let { modulePercentage, overallPercentage } = tests.includes(taskId)
      ? calculateTestingProgress(
          scenarioId,
          activityId,
          practiceMode,
          userInfo.role === "VALIDATION_STUDY",
          isCompleted,
        )
      : calculateTrainingProgress(
          moduleId,
          scenarioId,
          activityId,
          practiceMode,
          isCompleted,
        );

    const testingScore = tests.includes(taskId)
      ? await fetchTestingScore(userInfo.participantId, taskId, moduleId)
      : 0;

    return {
      taskId,
      moduleId,
      scenarioId,
      activityId,
      practiceMode,
      assetPath,
      type,
      progress: modulePercentage,
      overallProgress: overallPercentage,
      testingScore,
      done,
    };
  }

  /** @param {FocusEvent} event */
  function onListCardFocus(event) {
    /** @type {HTMLElement} */
    const card = event.target;
    /** @type {HTMLElement} */
    const list = modulesListRef.current;
    if (!list) return;

    const listRect = list.getBoundingClientRect();
    const cardRect = card.getBoundingClientRect();
    const parentClass = list.parentElement.classList;

    if (parentClass.contains("horizontal")) {
      const widthOffset = Math.max(0, listRect.width - cardRect.width) / 2;
      const leftOffset = cardRect.left - listRect.left - widthOffset;
      const rightOffset = cardRect.right - listRect.right + widthOffset;

      if (leftOffset < 0) {
        list.scrollBy({ left: Math.round(leftOffset), behavior: "smooth" });
      } else if (rightOffset > 0) {
        list.scrollBy({ left: Math.round(rightOffset), behavior: "smooth" });
      }
    } else if (parentClass.contains("vertical")) {
      const heightOffset = Math.max(0, listRect.height - cardRect.height) / 2;
      const topOffset = cardRect.top - listRect.top - heightOffset;
      const bottomOffset = cardRect.bottom - listRect.bottom + heightOffset;

      if (topOffset < 0) {
        list.scrollBy({ top: Math.round(topOffset) });
      } else if (bottomOffset > 0) {
        list.scrollBy({ top: Math.round(bottomOffset) });
      }
    }
  }

  function cardListPlaceHolder() {
    return Array.from(Array(10), (_i, k) => (
      <Card
        key={`card-loading-${k}`}
        className={`card-loading-${k}`}
        style={{ animationDelay: `${50 * k}ms` }}
        tabIndex={-1}
      >
        <header>
          <strong>-----</strong>
          <strong>---</strong>
        </header>
        <p>
          <strong>-------</strong>
        </p>
      </Card>
    ));
  }

  async function loadCards(trainingComplete) {
    const cards = [];

    if (activityState.taskId === "preTest") {
      setMainCardBG("var(--card-bg-color-var1)");
    } else if (activityState.taskId === "postTest") {
      setMainCardBG("var(--card-bg-color-var5)");
    } else if (activityState.taskId === "postDrivingTest") {
      setMainCardBG("var(--card-bg-color-var6)");
    } else if (trainingComplete) {
      setMainCardBG("var(--card-bg-color-var2)");
    }

    if (
      cardListType === "complete" &&
      (activityState.done || activityState.taskId !== "preTest")
    ) {
      cards.push(
        <Card
          key="pre-test"
          id="pre-test"
          className="dashboard-modules-card"
          bgColor="var(--card-bg-color-var1)"
          disabled
          showCheck
          tabIndex={0}
          onFocus={onListCardFocus}
        >
          <header>Quiz 1</header>
          <p>Hazard Perception</p>
        </Card>,
      );
    }

    if (
      cardListType === "up-next" &&
      activityState.taskId === "preTest" &&
      ["RESEARCHER", "EXPERIMENT", "EXPERIMENT_AM"].includes(userInfo.role)
    ) {
      cards.push(
        <Card
          key="training"
          id="training"
          className="dashboard-modules-card"
          bgColor="var(--card-bg-color-var2)"
          animateUnlock={activityState.done}
          disabled={!activityState.done}
          tabIndex={0}
          onClick={() => startNewTask("training")}
          onFocus={onListCardFocus}
        >
          <header>Training</header>
          <p>
            {userInfo.role === "EXPERIMENT_AM"
              ? "Attention Maintenance"
              : "Hazard Perception"}
          </p>
        </Card>,
      );
    } else if (
      activityState.taskId === "training" ||
      (cardListType === "complete" &&
        ["postTest", "postDrivingTest"].includes(activityState.taskId) &&
        ["RESEARCHER", "EXPERIMENT", "EXPERIMENT_AM"].includes(userInfo.role))
    ) {
      let include = cardListType === "complete";

      for (let i = 1; i <= 3; i++) {
        const moduleId = "module_" + i;
        const activityId = userInfo.role === "EXPERIMENT_AM" ? "AM" : "S";

        if (!trainingComplete && moduleId === activityState.moduleId) {
          include = !include;
          setMainCardBG(`var(--card-bg-color-var${i + 1})`);
          continue;
        }
        if (!include) continue;

        let assetPathModuleId = null;

        if (userInfo.role === "EXPERIMENT_AM") {
          assetPathModuleId =
            i === 1 ? "module_2" : i === 2 ? "module_4" : "module_6";
        } else {
          assetPathModuleId =
            i === 2 ? "module_3" : i === 3 ? "module_5" : "module_1";
        }
        cards.push(
          <Card
            key={i}
            id={moduleId}
            className="dashboard-modules-card"
            bgColor={`var(--card-bg-color-var${i + 1})`}
            disabled={cardListType === "up-next"}
            onClick={() => {
              const scenario = getScenarioType(assetPathModuleId)
                .replace(/-/, "")
                .toLowerCase();
              let assetPath = null;
              let scenarioId = null;

              if (userInfo.role === "EXPERIMENT_AM") {
                [assetPath, scenarioId] =
                  scenario === "intersections"
                    ? [`${assetPathModuleId}/${scenario}_2`, `${scenario}_2`] // first scenario in intersections is a practice activity
                    : [`${assetPathModuleId}/${scenario}_1`, `${scenario}_1`];
              } else {
                assetPath = `${assetPathModuleId}/${scenario}_1/${activityId}`;
                scenarioId = `${scenario}_1`;
              }

              navigate("/scenario", {
                state: {
                  ...userInfo,
                  timeStarted: Date.now(),
                  taskId: "training",
                  moduleId: moduleId,
                  scenarioId: scenarioId,
                  activityId: activityId,
                  practiceMode: false,
                  assetPath: assetPath,
                  type: userInfo.role === "EXPERIMENT_AM" ? "AM" : "HA",
                },
              });
            }}
            tabIndex={0}
            onFocus={onListCardFocus}
          >
            <header>{getModuleName(moduleId)}</header>
            <p>{getScenarioType(assetPathModuleId)}</p>
          </Card>,
        );
      }
    }

    if (
      cardListType === "up-next" &&
      !["postTest", "postDrivingTest"].includes(activityState.taskId) &&
      userInfo.role !== "VALIDATION_STUDY"
    ) {
      const unlockPostTest =
        (userInfo.role === "CONTROL" || activityState.taskId === "training") &&
        activityState.done;
      cards.push(
        <Card
          key="post-test"
          id="post-test"
          className="dashboard-modules-card"
          bgColor="var(--card-bg-color-var5)"
          animateUnlock={unlockPostTest}
          disabled={!unlockPostTest}
          tabIndex={0}
          onClick={() => startNewTask("post-test")}
          onFocus={onListCardFocus}
        >
          <header>Quiz 2</header>
          <p>Hazard Perception</p>
        </Card>,
      );
    }

    if (
      cardListType === "complete" &&
      activityState.taskId === "postDrivingTest" &&
      userInfo.role !== "VALIDATION_STUDY"
    ) {
      cards.push(
        <Card
          key="post-test"
          id="post-test"
          className="dashboard-modules-card"
          bgColor="var(--card-bg-color-var5)"
          disabled
          showCheck
          tabIndex={0}
          onFocus={onListCardFocus}
        >
          <header>Quiz 2</header>
          <p>Hazard Perception</p>
        </Card>,
      );
    }

    if (
      cardListType === "up-next" &&
      activityState.taskId !== "postDrivingTest" &&
      userInfo.role !== "VALIDATION_STUDY"
    ) {
      cards.push(
        <Card
          key="post-driving-test"
          id="post-driving-test"
          className="dashboard-modules-card"
          bgColor="var(--card-bg-color-var6)"
          animateUnlock={userInfo.postDrivingTestActivated}
          disabled={!userInfo.postDrivingTestActivated}
          tabIndex={0}
          onClick={() => startNewTask("post-driving-test")}
          onFocus={onListCardFocus}
        >
          <header>Quiz 3</header>
          <p>Hazard Perception</p>
        </Card>,
      );
    }

    const list = document.querySelector(".modules-list");
    list.style.opacity = 0;
    await pause(50);
    setCardList(cards);
    await pause(50);
    list.style.opacity = 1;
  }

  function mainCardLabel() {
    if (!userInfo || !activityState.moduleId) {
      return (
        <>
          <header>
            <strong>------------</strong>
            <strong>-----</strong>
          </header>
          <section>
            <strong>----------</strong>
          </section>
        </>
      );
    }

    const isHA = activityState.type === "HA";
    const isPractice = activityState.practiceMode;
    const isTraining = activityState.taskId === "training";
    const isTest = tests.includes(activityState.taskId);
    const isIncomplete = activityState.progress !== 100;

    const taskName = getTaskName(activityState.taskId, userInfo.role);
    const cardTitle = (
      <InfoTooltip
        className="dark-info-tooltip"
        content={taskName}
        placement="top"
      >
        <header>{taskName}</header>
      </InfoTooltip>
    );

    const moduleName = getModuleName(activityState.moduleId);
    const scenarioName = getScenarioName(activityState.scenarioId);
    const activityName = getActivityName(activityState.activityId);

    return (
      <>
        {cardTitle}
        <section>
          {isPractice
            ? "Practice Question"
            : isTraining
              ? moduleName
              : scenarioName}
        </section>
        {!isPractice && isHA && (
          <p>
            {isTraining ? scenarioName : isTest && isIncomplete && activityName}
          </p>
        )}
      </>
    );
  }

  function mainCardButtonLabel() {
    if (!activityState.moduleId) return "Loading...";
    if (isModulePaused) return `Training Paused`;

    const label = [];
    if (document.documentElement.clientWidth > 500) {
      activityState.taskId === "training"
        ? label.push("Module")
        : label.push("Quiz");
    }

    if (activityState.progress === 0) {
      label.unshift("Start");
    } else if (activityState.progress === 100) {
      activityState.taskId === "training"
        ? label.unshift("Review")
        : label.push("Complete");
    } else label.unshift("Continue");

    return label.join(" ");
  }

  function mainCardBtnOutline() {
    const btn = mainCardBtnRef.current;
    const cardBGVar = /var\(([\w\W]+)\)/g.exec(mainCardBG)[1];
    const cardColor = getCssVar(btn, cardBGVar);
    const bgColor = getCssVar(btn, "--main-bg-color");
    if (!chroma.valid(cardColor) || !chroma.valid(bgColor)) return "";

    return chroma
      .mix(chroma(cardColor).saturate(1.5).darken(1.2), bgColor, 0.7)
      .hex();
  }

  async function continueModule() {
    if (activityState.taskId === "preTest") {
      import(
        userInfo.role === "VALIDATION_STUDY"
          ? "../assets/activity-order/pretest-val.json"
          : "../assets/activity-order/pretest-ha.json"
        // : userInfo.role === "EXPERIMENT_AM"
        //   ? "../assets/activity-order/pretest-ha-and-am.json"
        //   : "../assets/activity-order/pretest-ha.json"
      )
        .then((res) => {
          const activityOrder = res.default;
          navigate("/scenario", {
            state: {
              ...userInfo,
              timeStarted: Date.now(),
              ...activityState,
              activityOrder,
            },
          });
        })
        .catch(console.log);
    } else if (activityState.taskId === "training") {
      if (
        userInfo.inPerson &&
        activityState.taskId &&
        isFirstActivityInTrainingModule(activityState)
      ) {
        const pauseOverKey = `${userInfo.emailHash}-${activityState.moduleId}-pauseOver`;
        localStorage.setItem(pauseOverKey, "true");
        clearInterval(pauseTimerRef.current);
      }

      navigate("/scenario", {
        state: {
          ...userInfo,
          timeStarted: Date.now(),
          ...activityState,
        },
      });
    } else if (activityState.taskId === "postTest") {
      import(`../assets/activity-order/posttest.json`)
        .then((res) => {
          const activityOrder = res.default;
          navigate("/scenario", {
            state: {
              ...userInfo,
              timeStarted: Date.now(),
              ...activityState,
              activityOrder,
            },
          });
        })
        .catch(console.log);
    } else if (activityState.taskId === "postDrivingTest") {
      import(`../assets/activity-order/post-driving-test.json`)
        .then((res) => {
          const activityOrder = res.default;
          navigate("/scenario", {
            state: {
              ...userInfo,
              timeStarted: Date.now(),
              ...activityState,
              activityOrder,
            },
          });
        })
        .catch(console.log);
    }
  }

  async function startNewTask(taskId) {
    if (userInfo.role === "VALIDATION_STUDY") {
      return;
    }
    await axios
      .post(
        `${ServerUrl}/users/${userInfo.participantId}/activate-${taskId}`,
        {},
        {
          withCredentials: true,
        },
      )
      .then(async (res) => {
        if (res.status === 200) {
          setChangingTask(true);
          setCardList([]);
          setOverallProgress(0);
          await pause(100);
          await fetchActivityInfo();
          await pause(500);
          setChangingTask(false);
        }
      });
  }

  return (
    <DashContainer userInfo={userInfo}>
      <Helmet>
        <title>Dashboard | Hazard Perception</title>
      </Helmet>
      <div className="dashboard-cards">
        <Card
          id={activityState.moduleId}
          className={clsx(
            "dashboard-main-card",
            changingTask && "hide-content",
            !activityState.moduleId && "loading",
          )}
          bgColor={mainCardBG}
          height={isTablet && "16rem"}
          tabIndex={0}
        >
          <CircularProgressBar
            className="main-card-progress"
            stroke={isTablet ? 18 : 13}
            width={isTablet ? "7rem" : "15rem"}
            hideSymbol={isTablet}
            percentage={activityState.progress}
            variant="light"
            tint={mainCardBG}
            isTest={tests.includes(activityState.taskId)}
            correctAnswers={activityState.testingScore}
            numActivitiesTotal={
              tests.includes(activityState.taskId) &&
              (userInfo.role === "VALIDATION_STUDY" ? 27 : 18)
            }
          />
          <div className="main-card-info">{mainCardLabel()}</div>
          <Button
            className="btn-main-card"
            icon={
              activityState.moduleId &&
              (activityState.progress === 100 &&
              tests.includes(activityState.taskId) ? (
                <IconCheck stroke={2.5} />
              ) : isModulePaused ? (
                <IconPlayerPause stroke={1.8} />
              ) : (
                <IconArrowRight stroke={2.2} />
              ))
            }
            reverse
            label={mainCardButtonLabel()}
            variant="dark"
            bgColor="var(--default-card-bg-color)"
            fontSize={isPortraitMobile ? "1.7rem" : "1.8rem"}
            onClick={() =>
              isModulePaused ? setIsPausedOpen(true) : continueModule()
            }
            disabled={
              !activityState.moduleId ||
              (activityState.progress === 100 &&
                tests.includes(activityState.taskId))
            }
            styles={{
              "--btn-focus-outline": mainCardBtnOutline(),
            }}
            ref={mainCardBtnRef}
          />
        </Card>
        <Card
          className="dashboard-avatar"
          width="auto"
          height="auto"
          bgColor="transparent"
        >
          <SVG src="avatar-default" fadeDurationMS={100} />
          <Card className="platform" bgColor="var(--avatar-platform-color)" />
        </Card>
      </div>
      {!isTablet && <div className="separator" />}
      <div
        className="dashboard-modules"
        style={{
          visibility:
            userInfo && userInfo.role === "VALIDATION_STUDY"
              ? "hidden"
              : "visible",
        }}
      >
        <div className="dashboard-modules-title">
          <header>
            {truncListTitle ? (
              <span>
                Your <br /> Tasks
              </span>
            ) : (
              <span>
                Your Tasks <br /> & Modules
              </span>
            )}
          </header>
          <ButtonToggle
            choices={
              isPortraitMobile
                ? [
                    <IconCheck key="complete" stroke={2.6} />,
                    <IconSignRight key="up-next" />,
                  ]
                : ["Complete", "Up Next"]
            }
            firstOn={cardListType === "complete"}
            actions={[
              () => setCardlistType("complete"),
              () => setCardlistType("up-next"),
            ]}
            className="modules-button-toggle"
            tippys={
              isPortraitMobile && [
                { content: "Complete" },
                { content: "Up Next" },
              ]
            }
          />
        </div>
        <ScrollList
          className="modules-list"
          direction={isTablet ? "vertical" : "horizontal"}
          ref={modulesListRef}
          showEdge
          defer
        >
          {cardList}
        </ScrollList>
      </div>
      {!isTablet && (
        <Card className="dashboard-modules-progress">
          <span>Progress</span>
          <ContinuousProgressBar initAnimate percentage={overallProgress} />
        </Card>
      )}
      <TrainingPausedModal
        modalState={[isPausedOpen, setIsPausedOpen]}
        continueModule={continueModule}
      />
    </DashContainer>
  );
}

export default Dashboard;

