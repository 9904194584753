import { useOnResize } from "@src/Hooks";
import {
  IconAlertCircleFilled,
  IconAlertTriangleFilled,
  IconCheck,
  IconLoader2,
  IconMaximize,
  IconMinimize,
  IconMinus,
  IconPlus,
  IconReload,
  IconX,
} from "@tabler/icons-react";
import axios from "axios";
import clsx from "clsx";
import {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import { ServerUrl, loadFor, pause } from "../Utils";
import Card from "../components/Card";
import { DisplayInfo, ScenarioInfo } from "../contexts/Contexts";
import { Button } from "./Button";
import { SVG } from "./SVG";
import Tooltip from "./Tooltip";

/** @type {ForwardRef<ActivityHAProps, RefType<PlayerWindow>} */
const ActivityHA = forwardRef((props, ref) => {
  const { practiceAnswer, setCheckedDriverView } = props;
  const { states, setStates, actions } = useContext(ScenarioInfo);

  /** @type {Defaults["instructionsHA"]} */
  const instructionJSON = states.instructionJSON;

  const {
    playerLoading,
    activityState,
    currInstructions,
    responses,
    isDriverView,
    scenarioWindow,
    frameOpacity,
  } = states;

  // prettier-ignore
  const {
    setCurrInstructions, 
    setIsCompleted, 
    setResponses, 
    setHasCorrectAnswer,
    setIsDriverView 
  } = setStates;

  const [lastClick, setLastClick] = useState({ x: -1, y: -1 });
  const [clickRadii, setClickRadii] = useState({ x: -1, y: -1 });
  const [dragging, setDragging] = useState(false);
  const [markerState, setMarkerState] = useState("");
  const [showMarker, setShowMarker] = useState(false);
  const [markerTimeout, setMarkerTimeout] = useState(null);
  const [activityAsset, setActivityAsset] = useState(null);

  const correctAreaRef = useRef(null);
  // show hint when clicking during guess
  const guessTooltipRef = useRef(null);
  const guessClickRef = useRef(null);

  const tests = ["preTest", "postTest", "postDrivingTest"];

  const location = useLocation();

  Object.assign(actions, { eraseEllipse });

  useOnResize(() => {
    const guessTooltip = getHintTooltip();
    if (guessTooltip && guessTooltip.state.isShown) {
      guessTooltip.hide();
    }
  }, []);

  useEffect(() => {
    // setIsCompleted(null);
    // setHasCorrectAnswer(null);
    setIsDriverView(false); // redundant - done when next button is clicked in Scenario.jsx
    setCheckedDriverView(false);
    setClickRadii({ x: -1, y: -1 });
    setLastClick({ x: -1, y: -1 });
  }, [activityState]);

  useEffect(() => {
    isDriverView && setCheckedDriverView(true);
    setClickRadii({ x: -1, y: -1 });

    importAssets();
  }, [isDriverView, activityState]);

  useEffect(() => {
    const guessHintTooltip = getHintTooltip();
    if (guessHintTooltip) guessHintTooltip.hide();
  }, [activityAsset, currInstructions]);

  useEffect(() => {
    if (!instructionJSON.instructions) return;

    const isPractice = activityState.practiceMode;

    const view = isDriverView
      ? isPractice && practiceAnswer
        ? "driver-view-with-answer"
        : "driver-view"
      : "top-view";

    const instructionsType = isPractice
      ? "instructions-practice"
      : "instructions";

    let newInstructions = [""];

    if (view === "top-view") {
      // top-view always has the same instructions
      newInstructions = instructionJSON[instructionsType][view];
    } else {
      if (
        activityState.taskId === "training" &&
        isDriverView &&
        (responses.correct > 0 || responses.incorrect >= 3)
      ) {
        showEllipse();
      }

      if (
        tests.includes(activityState.taskId) &&
        !activityState.practiceMode &&
        !responses.last
      ) {
        // instructions to attempt test activity
        newInstructions = instructionJSON[instructionsType][view];
      } else if (
        tests.includes(activityState.taskId) &&
        !activityState.practiceMode &&
        responses.last
      ) {
        // no feedback after attempting a test activity
        newInstructions = instructionJSON["test-instructions"];
      } else if (!responses.last) {
        // driver view, but no responses yet
        newInstructions = instructionJSON[instructionsType][view];
      } else if (responses.last === "correct") {
        const idx = Math.max(Math.min(responses.correct, 1) - 1, 0);
        const correctFeedbackType = activityState.practiceMode
          ? "correct-feedback-practice"
          : "correct-feedback";
        newInstructions = instructionJSON[correctFeedbackType][idx];
      } else if (responses.last === "incorrect") {
        const idx = Math.max(Math.min(responses.incorrect, 3) - 1, 0);
        const incorrectFeedbackType = activityState.practiceMode
          ? "incorrect-feedback-practice"
          : "incorrect-feedback";
        newInstructions = instructionJSON[incorrectFeedbackType][idx];
      }
    }
    setCurrInstructions(newInstructions);
  }, [responses, isDriverView, activityState, practiceAnswer, instructionJSON]);

  // TODO: load before
  useEffect(() => {
    if (playerLoading) return;
    if (activityState.practiceMode && practiceAnswer && isDriverView) {
      const pid = `users/${location.state.participantId}`;
      const tid = `tasks/${activityState.taskId}`;
      const mid = `modules/${activityState.moduleId}`;
      const sid = `scenarios/${activityState.scenarioId}`;
      const aid = `activities/${activityState.activityId}`;

      axios
        .get(`${ServerUrl}/${pid}/${tid}/${mid}/${sid}/${aid}`, {
          withCredentials: true,
        })
        .then((response) => {
          drawEllipse(
            response.data.data.hitboxCoordinates,
            response.data.data.hitboxRadii,
            activityState.practiceMode,
          );
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else if (!isDriverView) {
      eraseEllipse();
    }
  }, [practiceAnswer, isDriverView, playerLoading]);

  function importAssets() {
    const assetPath = `${activityState.assetPath}`;
    const [mid, sid, aid] = assetPath.split("/");
    const file = isDriverView ? "driver-view" : "top-view";
    setActivityAsset(null);

    loadFor(frameOpacity ? 150 : 500, () => {
      return isDriverView
        ? import(`../assets/module-content/${mid}/${sid}/${aid}/${file}.png`)
        : import(`../assets/module-content/${mid}/${sid}/${aid}/${file}.mp4`);
    })
      .then((res) => setActivityAsset(res.default))
      .catch(console.log);
  }

  function fadeOutMarker(fadeOutTime = 1500) {
    if (markerTimeout) {
      clearTimeout(markerTimeout);
    }
    const newTimeout = setTimeout(async () => {
      setShowMarker(false);
    }, fadeOutTime);
    setMarkerTimeout(newTimeout);
  }

  function eraseEllipse() {
    const correctArea = correctAreaRef.current;
    if (!correctArea) return;
    correctArea.classList.remove("visible");
  }

  function showEllipse() {
    const correctArea = correctAreaRef.current;
    if (!correctArea) return;
    correctArea.classList.add("visible");
  }

  function drawEllipse(coordinates, radii, glow = false) {
    const correctArea = correctAreaRef.current;
    if (!correctArea) return;
    correctArea.classList.add("visible");
    correctArea.style.left = `${coordinates.x * 100}%`;
    correctArea.style.top = `${coordinates.y * 100}%`;
    correctArea.style.width = `${radii.x * 200}%`;
    correctArea.style.height = `${radii.y * 200}%`;

    glow
      ? correctArea.classList.add("glow-pulse")
      : correctArea.classList.remove("glow-pulse");
  }

  function getHintTooltip() {
    const guessTooltip = guessTooltipRef.current;
    if (guessTooltip.state.isDestroyed) return;
    return guessTooltip;
  }

  // show hint tooltip if user clicks to
  // answer during practice "guess"
  async function continueHintTooltip(event) {
    if (!isDriverView) return;
    const guessClick = guessClickRef.current;
    const guessTooltip = getHintTooltip();
    if (!guessClick || !guessTooltip) return;
    if (guessTooltip.state.isShown) {
      guessTooltip.hide();
      await pause(guessTooltip.props.duration);
    }
    const frame = guessClick.parentNode;
    if (!frame) return;
    const rect = frame.getBoundingClientRect();
    const clickX = event.clientX - rect.left;
    const clickY = event.clientY - rect.top;
    guessClick.style.left = `${clickX}px`;
    guessClick.style.top = `${clickY}px`;
    guessTooltip.show();
  }

  // take coords of click with respect to scenario window
  async function getClickCoordinates(event) {
    // Do not allow click if user is in top view
    if (!isDriverView || playerLoading) return;

    const isPracticeMode = activityState.practiceMode;
    const isPreTest = activityState.taskId === "preTest";
    const isAnswered = responses.correct + responses.incorrect >= 1;
    const isCorrect = responses.correct >= 1;

    if (isPracticeMode && !practiceAnswer) {
      continueHintTooltip(event);
      return;
    }

    // TODO: better way (use instructionJSON["correct-feedback"].length ??)

    // Do not allow click if practice mode is on and
    // user is on driver view and has already answered correctly or the practice answer (ellipse) isn't shown yet
    if (isPracticeMode && (isCorrect || !practiceAnswer)) {
      continueHintTooltip(event);
      return;
    }

    // Do not allow click if task is preTest/postTest and
    // practice mode is not on and
    // user has already answered (correctly or incorrectly)
    if (tests.includes(activityState.taskId) && !isPracticeMode && isAnswered) {
      continueHintTooltip(event);
      return;
    }

    if (
      activityState.taskId === "training" &&
      responses.correct < 1 &&
      responses.incorrect >= 3
    ) {
      continueHintTooltip(event);
      return;
    }

    if (markerTimeout) {
      clearTimeout(markerTimeout);
    }
    setMarkerState("");
    setLastClick({ x: -1, y: -1 });
    setClickRadii({ x: -1, y: -1 });

    const rect = event.target.getBoundingClientRect();
    const clickX = (event.clientX - rect.left) / rect.width;
    const clickY = (event.clientY - rect.top) / rect.height;
    setLastClick({ x: clickX, y: clickY });

    if (event.type !== "click") {
      // remove drag preview
      setDragging(true);
      setShowMarker(false);
      clearTimeout(markerTimeout);
      event.dataTransfer.setDragImage(new Image(), 0, 0);
      return;
    }

    setShowMarker(true);

    const pid = `users/${location.state.participantId}`;
    const tid = `tasks/${activityState.taskId}`;
    const mid = `modules/${activityState.moduleId}`;
    const sid = `scenarios/${activityState.scenarioId}`;
    const aid = `activities/${activityState.activityId}`;

    await axios
      .put(
        `${ServerUrl}/${pid}/${tid}/${mid}/${sid}/${aid}/add-answer/`,
        {
          coordinates: { x: clickX, y: clickY },
          timeStarted: location.state.timeStarted,
          timeEnded: Date.now(),
        },
        {
          withCredentials: true,
        },
      )
      .then((res) => {
        // determine correctness
        const activity = res.data.data;

        setIsCompleted(activity.isCompleted);
        setHasCorrectAnswer(activity.hasCorrectAnswer);

        if (
          activity.lastCorrectIndex ===
          activity.responseStartTimes.length - 1
        ) {
          tests.includes(activityState.taskId) && !activityState.practiceMode
            ? setMarkerState("neutral")
            : setMarkerState("correct");
          setResponses({
            ...responses,
            correct: ++responses.correct,
            last: "correct",
          });
          activityState.taskId === "training" &&
            drawEllipse(activity.hitboxCoordinates, activity.hitboxRadii);
        } else {
          tests.includes(activityState.taskId) && !activityState.practiceMode
            ? setMarkerState("neutral")
            : setMarkerState("incorrect");

          if (responses.correct < 1 && responses.incorrect >= 2) {
            drawEllipse(activity.hitboxCoordinates, activity.hitboxRadii, true);
          }

          setResponses({
            ...responses,
            incorrect: ++responses.incorrect,
            last: "incorrect",
          });
        }
        fadeOutMarker();
      })
      .catch((err) => {
        fadeOutMarker(400);
        continueHintTooltip(event);
        console.log(err);
        // handle expired session
      });
  }

  function getClickRadii(event) {
    if (!isDriverView || responses.correct >= 2) return;
    if (!event.screenX && !event.screenY) return;
    const rect = event.target.getBoundingClientRect();
    const clickX = (event.clientX - rect.left) / rect.width;
    const clickY = (event.clientY - rect.top) / rect.height;
    const radiusX = Math.abs(clickX - lastClick.x);
    const radiusY = Math.abs(clickY - lastClick.y);
    setClickRadii({ x: radiusX, y: radiusY });
  }

  return (
    <PlayerWindow
      src={activityAsset}
      animated={!isDriverView}
      onClick={getClickCoordinates}
      // onDrag={getClickRadii}
      // onDragStart={getClickCoordinates}
      // onDragEnd={() => {
      //   if (clickRadii.x < 0 || clickRadii.y < 0) return;
      //   setDragging(false);
      // }}
      // draggable={isDriverView}
      onRetryLoad={importAssets}
      ref={ref}
      skillType="HA"
      autoStart
      loop
    >
      <Tooltip
        className="guess-tooltip"
        content={
          <>
            Please click <b>Next</b> to continue.
          </>
        }
        onCreate={(inst) => (guessTooltipRef.current = inst)}
        placement="top"
        popperOptions={{
          modifiers: [
            {
              name: "flip",
              options: {
                fallbackPlacements: ["left", "right", "bottom"],
                boundary: scenarioWindow,
              },
            },
            {
              name: "preventOverflow",
              options: {
                boundary: scenarioWindow,
                altAxis: true,
              },
            },
          ],
        }}
        maxWidth="10rem"
        zIndex={0}
      >
        <div className="guess-click" ref={guessClickRef} />
      </Tooltip>
      {clickRadii.x >= 0 && (
        <div
          className="correct-overlay"
          style={{
            top: `${lastClick.y * 100}%`,
            left: `${lastClick.x * 100}%`,
            height: `${clickRadii.y * 200}%`,
            width: `${clickRadii.x * 200}%`,
          }}
        />
      )}
      {
        <div
          ref={correctAreaRef}
          className="correct-area"
          style={{
            opacity: +(isDriverView && responses.last === "correct"),
            display: !isDriverView && "none",
          }}
        />
      }
      {!dragging && (
        <div
          className={clsx("click-marker", showMarker && "visible", markerState)}
          style={{
            top: `${lastClick.y * 100}%`,
            left: `${lastClick.x * 100}%`,
          }}
        >
          <IconCheck className="check-icon" display="none" />
          <IconX className="x-icon" display="none" />
        </div>
      )}
    </PlayerWindow>
  );
});

/** @type {ForwardRef<ActivityAMProps, RefType<PlayerWindow>} */
const ActivityAM = forwardRef((props, ref) => {
  // prettier-ignore
  const {
    practiceAnswer,
    setIsGlanceCompletedForPractice,
    setPracticeText,
  } = props;

  const { states, setStates, actions } = useContext(ScenarioInfo);
  const { isMobile } = useContext(DisplayInfo);

  /** @type {Defaults["instructionsAM"]} */
  const instructionJSON = states.instructionJSON;

  const {
    frameOpacity,
    activityState,
    responses,
    topView,
    topViewUpNext,
    mirrorView,
    mirrorDisabled,
    showQuestion,
    playing,
    isGridAttempted,
    allowReset,
    performReset,
    isCompleted,
    ended,
    gridSelections,
  } = states;

  const {
    setCurrInstructions,
    setIsCompleted,
    setResponses,
    setHasCorrectAnswer,
    setTopView,
    setTopViewUpNext,
    setMirrorView,
    setMirrorDisabled,
    setShowQuestion,
    setNoGlanceMade,
    setIsGridAttempted,
    setAllowReset,
    setPerformReset,
    setPlaying,
    setEnded,
    setGridSelections,
  } = setStates;

  const [topViewVid, setTopViewVid] = useState(null);
  const [activityVid, setActivityVid] = useState(null);
  const [rearViewImg, setRearViewImg] = useState(null);
  const [rearViewBlankImg, setRearViewBlankImg] = useState(null);
  const [isGlanceAttempted, setIsGlanceAttempted] = useState(false);
  const [retried, setRetried] = useState(false);

  const [currRvmTime, setCurrRvmTime] = useState(0);
  const [rvmIntervals, setRvmIntervals] = useState([]);
  const [rvmForbid, setRvmForbid] = useState(false);
  const [startTime, setStartTime] = useState(-1);
  const [dangerAlert, setDangerAlert] = useState(null);
  const [activityError, setActivityError] = useState(null);
  const [checking, setChecking] = useState(false);
  const [rvmAlert, setRvmAlert] = useState(false);

  const [forbiddenIntervals, setForbiddenIntervals] = useState([]);
  const [gridAnswers, setGridAnswers] = useState([]);

  const activityErrRef = useRef(activityError);
  const rvmAlertRef = useRef(rvmAlert);
  const currGlanceRef = useRef(null);
  activityErrRef.current = activityError;
  rvmAlertRef.current = rvmAlert;

  const tests = ["preTest", "postTest", "postDrivingTest"];
  const rvmShown = (mirrorView || showQuestion) && !mirrorDisabled;

  const location = useLocation();

  const GlanceErr = {
    tooLong: "err-duration",
    duringDanger: "err-hazard",
    tooLongAndDanger: "err-duration-hazard",
    noGlance: "err-no-glance",
  };

  const ResponseErr = {
    incorrectResponse: "incorrect-grid",
  };

  const AMPracticeText = {
    startText: <>Let's see if you can maintain your attention while driving</>,
    allowRVM: (
      <>
        A hazard probably won't appear here, so it's safe to use the rear
        view-mirror now{" "}
        <IconCheck
          stroke={4}
          color="rgb(102, 255, 102)"
          style={{ transform: "translate(0, 0.2rem)" }}
        />
      </>
    ),
    disallowRVM: (
      <>
        A hazard could appear here! It's not safe to use the rear-view mirror
        now
        <IconX
          stroke={4}
          color="rgb(255, 102, 102)"
          style={{ transform: "translate(0.2rem, 0.2rem)" }}
        />
      </>
    ),
  };

  Object.assign(actions, {
    addCorrectResponse,
    addIncorrectResponse,
    addNullResponse,
    handleToggleRVM,
    handlePlayAM,
    monitorGlance,
    submitGlanceOnly,
    checkGridSelections,
    handleSubmit,
  });

  useEffect(() => {
    if (checking || !ended) return;

    setStartTime(-1);
    setIsGlanceAttempted(true);

    const isPractice = activityState.practiceMode;

    if (isPractice) setPracticeText("");

    if (!rvmIntervals.length) {
      setActivityError(GlanceErr.noGlance);
      setNoGlanceMade(true);
      setAllowReset(false);
      setMirrorDisabled(true);
      addIncorrectResponse();

      if (!isPractice) {
        submitGlanceOnly();
      }
    } else if (activityError) {
      // not allowed to proceed to grid question if glance is incorrect
      // submit glances w/o grid attempt if incorrect (if correct, will be submitted after grid attempt)
      submitGlanceOnly();
      const validTopViewErrors = [
        GlanceErr.tooLong,
        GlanceErr.duringDanger,
        GlanceErr.tooLongAndDanger,
      ];
      if (validTopViewErrors.includes(activityError)) {
        setTopViewUpNext(true);
      }
      setMirrorDisabled(true);
      if (activityState.practiceMode) {
        setIsGlanceCompletedForPractice(true);
      }
      addIncorrectResponse();
    } else {
      addCorrectResponse();
    }
  }, [checking, ended]);

  useEffect(() => {
    if (performReset) {
      resetActivity(activityState.practiceMode);
    }
  }, [performReset]);

  useEffect(() => {
    // for grid view
    if (isGlanceAttempted) return;
    if (mirrorView) {
      setCurrRvmTime(Date.now());
      currGlanceRef.current = setTimeout(() => {
        if (!rvmAlertRef.current) {
          setRvmAlert("Bad Glance - Glance was too long!");

          // if (
          //   activityState.practiceMode
          // ) {
          //   resetActivity(true);
          // }

          // if (
          //   tests.includes(activityState.taskId) &&
          //   !activityState.practiceMode
          // ) {
          //   setAllowReset(false);
          // }
          setAllowReset(false);
        }
        if (activityErrRef.current === GlanceErr.duringDanger) {
          setActivityError(GlanceErr.tooLongAndDanger);
        } else if (!activityErrRef.current) {
          setActivityError(GlanceErr.tooLong);
        }
      }, 2000);
    } else if (currRvmTime) {
      clearTimeout(currGlanceRef.current);
      const start = (currRvmTime - startTime) / 1000;
      const end = (Date.now() - startTime) / 1000;
      const newGlance = {
        start: +start.toFixed(3),
        end: +end.toFixed(3),
      };
      rvmIntervals.push(newGlance);
      setChecking(false);
    }
  }, [isGlanceAttempted, mirrorView]);

  useEffect(() => {
    initialize();

    activityState.practiceMode && setPracticeText(AMPracticeText.startText);

    const tid = activityState.taskId;
    const mid = activityState.moduleId;
    const sid = activityState.scenarioId;
    const aid = activityState.activityId;
    const pid = location.state.participantId;

    axios
      .get(
        `${ServerUrl}/users/${pid}/tasks/${tid}/modules/${mid}/scenarios/${sid}/activities/${aid}`,
        {
          withCredentials: true,
        },
      )
      .then((response) => {
        setForbiddenIntervals(response.data.data.rearviewNotAllowed);
        setGridAnswers(response.data.data.correctGridResponse);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, [activityState]);

  useEffect(() => {
    if (rvmForbid && mirrorView) {
      if (!rvmAlert) {
        setRvmAlert("Bad Glance - Hazard could be nearby!");

        // if (activityState.practiceMode) {
        //   resetActivity(true);
        // }

        // if (
        //   tests.includes(activityState.taskId) &&
        //   !activityState.practiceMode
        // ) {
        //   setAllowReset(false);
        // }
        setAllowReset(false);
      }
      if (activityErrRef.current === GlanceErr.tooLong) {
        setActivityError(GlanceErr.tooLongAndDanger);
      } else if (!activityErrRef.current) {
        setActivityError(GlanceErr.duringDanger);
      }
    }
  }, [rvmForbid, mirrorView]);

  useEffect(() => {
    if (!instructionJSON) return;

    const isPractice = activityState.practiceMode;
    const isTestAndNotPractice =
      tests.includes(activityState.taskId) && !isPractice;

    const instructionType = isPractice
      ? "instructions-practice"
      : "instructions";

    const questionType =
      isPractice && practiceAnswer ? "question-with-answer" : "question";

    const incorrectFeedbackType = isPractice
      ? "incorrect-feedback-practice"
      : "incorrect-feedback";

    let newInstructions = [""];

    if (showQuestion) {
      if (!isGridAttempted) {
        newInstructions = instructionJSON[instructionType][questionType];
      } else {
        if (isTestAndNotPractice) {
          newInstructions = instructionJSON["test-instructions"];
        }
        if (!responses.last) return;
        newInstructions =
          responses.last === "correct"
            ? instructionJSON["correct-grid"]
            : instructionJSON[incorrectFeedbackType]["incorrect-grid"];
      }
    } else if (isGlanceAttempted) {
      // if (responses.correct < 1 && responses.incorrect >= 3) {
      //   setAllowReset(false);
      // }
      if (!activityError) {
        if (topView) {
          newInstructions = instructionJSON["top-view-after-correct"];
        } else {
          newInstructions =
            instructionJSON[isTestAndNotPractice ? "test-rvm" : "correct-rvm"];
        }
      } else {
        if (isTestAndNotPractice) {
          // no feedback after attempting a test activity
          newInstructions =
            instructionJSON[
              topView ? "top-view-after-correct" : "test-instructions"
            ];
        } else {
          const idx = Math.max(Math.min(responses.incorrect, 3) - 1, 0);
          const feedback = instructionJSON[incorrectFeedbackType];
          if (topView) {
            newInstructions = feedback["top-view-after-incorrect"];
          } else if (Object.values(GlanceErr).includes(activityError)) {
            newInstructions = isPractice
              ? feedback["incorrect-glance"][activityError]
              : feedback["incorrect-glance"][activityError][idx];
          }
        }
      }
      // } else if (isPractice && activityError) {
      //   // as practice can have an activity error without having attempted a glance - activity is reset instantly when a mistake is made
      //   const feedback = instructionJSON[incorrectFeedbackType];
      //   if (topView) {
      //     newInstructions = feedback["top-view-after-incorrect"];
      //   } else if (Object.values(GlanceErr).includes(activityError)) {
      //     newInstructions = feedback["incorrect-glance"][activityError];
      //   }
    } else {
      const view = showQuestion ? "question" : "driver-view";
      newInstructions = instructionJSON[instructionType][view];
    }
    setCurrInstructions(newInstructions);
  }, [
    isGlanceAttempted,
    isGridAttempted,
    showQuestion,
    practiceAnswer,
    responses,
    activityState,
    instructionJSON,
    activityError,
    topView,
  ]);

  function initialize(practice = false) {
    setMirrorDisabled(true);
    setMirrorView(false);
    setTopView(false);
    setTopViewUpNext(false);
    setShowQuestion(false);
    setPlaying(false);
    setRetried(false);
    setNoGlanceMade(false);
    setIsGlanceAttempted(false);
    setIsGlanceCompletedForPractice(false);
    setIsGridAttempted(false);
    setCurrRvmTime(0);
    setRvmIntervals([]);
    setGridSelections([]);
    clearInterval(dangerAlert);
    setRvmForbid(false);
    setRvmAlert(null);
    setEnded(false);
    setAllowReset(false);
    setPerformReset(false);

    addNullResponse();
    importAssets();

    // for practice, error should persist after we
    // restart the activity to display the mistake
    if (!practice) setActivityError(null);
  }

  function importAssets() {
    const assetPath = `${activityState.assetPath}`;
    const [mid, sid] = assetPath.split("/");

    setActivityVid(null);
    setTopViewVid(null);
    setRearViewImg(null);
    setRearViewBlankImg(null);

    loadFor(frameOpacity ? 150 : 500, () => {
      return import(`../assets/module-content/${mid}/${sid}/driver-view.mp4`);
    })
      .then((response) => setActivityVid(response.default))
      .catch(console.log);

    import(`../assets/module-content/${mid}/${sid}/top-view.mp4`)
      .then((response) => setTopViewVid(response.default))
      .catch(console.log);

    import(`../assets/module-content/${mid}/${sid}/rear-view.png`)
      .then((response) => setRearViewImg(response.default))
      .catch(console.log);

    import(`../assets/module-content/${mid}/${sid}/rear-view-blank.png`)
      .then((response) => setRearViewBlankImg(response.default))
      .catch(console.log);
  }

  function populateGrid() {
    const RVMGrid = [];
    for (let idx = 1; idx <= 12; idx++) {
      let rightInputAnswer = false;
      let wrongInputAnswer = false;
      let missedAnswer = false;

      const inAnswer = gridAnswers.indexOf(idx) !== -1;
      const inSelection = gridSelections.indexOf(idx) !== -1;
      const practiceModeAnswer = activityState.practiceMode && practiceAnswer;

      // for testing or practice, if the activity is complete or if during practice mode, the answer needs to be shown
      // (after the grid attempt, all colors will be shown, otherwise, only for the correct cells),
      // for training, if there has been a grid attempt & the activity is completed
      const showGridColors =
        tests.includes(activityState.taskId) || activityState.practiceMode
          ? isCompleted || (practiceModeAnswer && isGridAttempted)
          : isGridAttempted && isCompleted;

      // for testing, disable if during preTest or practice, either the answer is not show when in practice mode
      // or the activity is completed (only 1 attempt)
      // for training if grid question is attempted and activity is completed
      const isGridCellDisabled =
        tests.includes(activityState.taskId) || activityState.practiceMode
          ? isCompleted || (activityState.practiceMode && !practiceAnswer)
          : isGridAttempted && isCompleted;

      if (showGridColors) {
        if (inAnswer && inSelection) {
          rightInputAnswer = true;
        } else if (!inAnswer && inSelection) {
          wrongInputAnswer = true;
        } else if (inAnswer && !inSelection) {
          missedAnswer = true;
        }
      } else if (practiceModeAnswer) {
        if (inAnswer) {
          rightInputAnswer = true;
        }
      }

      RVMGrid.push(
        <RVMGridElement
          key={idx}
          idx={idx}
          rightInputAnswer={rightInputAnswer}
          wrongInputAnswer={wrongInputAnswer}
          missedAnswer={missedAnswer}
          disabled={isGridCellDisabled}
          interactive={showQuestion}
        />,
      );
    }
    return RVMGrid;
  }

  // function radioBtn(option) {
  //   const optionStr = `${option}`;
  //   const onClick = () => setSelectedChoice(optionStr);
  //   return (
  //     <span
  //       className={clsx(
  //         "radio-btn",
  //         selectedChoice === optionStr && "selected"
  //       )}
  //       tabIndex={0}
  //       onClick={onClick}
  //     >
  //       <Button
  //         id={optionStr}
  //         className="choice"
  //         icon={<div className="indicator" />}
  //         depth="0.4rem"
  //         fontSize="1.4rem"
  //         variant={selectedChoice !== optionStr && "secondary"}
  //         tabIndex={-1}
  //         tag={selectedChoice === optionStr && "pressed"}
  //         toggle
  //       />
  //       <label htmlFor={optionStr}>{optionStr}</label>
  //     </span>
  //   );
  // }

  function resetActivity(isPractice = false) {
    clearTimeout(currGlanceRef.current);
    pause(400).then(() => {
      initialize();
      isPractice && setPracticeText(AMPracticeText.startText);
    });
  }

  function addCorrectResponse() {
    setResponses({
      ...responses,
      correct: ++responses.correct,
      last: "correct",
    });
  }

  function addIncorrectResponse() {
    setResponses({
      ...responses,
      incorrect: ++responses.incorrect,
      last: "incorrect",
    });
  }

  function addNullResponse() {
    setResponses({
      ...responses,
      last: null,
    });
  }

  function handleToggleRVM() {
    setRvmAlert(null);
    if (!isGlanceAttempted) {
      setMirrorView(!mirrorView);
    } else {
      setShowQuestion(true);
      if (activityState.practiceMode) {
        setIsGlanceCompletedForPractice(true);
      }
      addNullResponse(); // for avatar
    }
  }

  function handlePlayAM() {
    // if ((playing || showQuestion) && allowReset) {
    //   addNullResponse(); // for avatar
    //   setStartTime(-1);
    //   resetActivity();
    //   tests.includes(activityState.taskId) && setAllowReset(false);
    // }
    // if (topView) {
    //   setPlaying(false);
    //   setTopView(false);
    // }
    if (allowReset) {
      addNullResponse(); // for avatar
      setStartTime(-1);
      setPerformReset(true);
    } else {
      setAllowReset(true);
      setPlaying(true);
      setRetried(true);
    }
  }

  function monitorGlance(thisStartTime) {
    let idx = 0;
    let currDanger;
    const isPractice = activityState.practiceMode;
    setDangerAlert(
      setInterval(() => {
        currDanger = forbiddenIntervals[idx];
        const currProgress = (Date.now() - thisStartTime) / 1000;
        if (currDanger && currDanger.start > currProgress) {
          setRvmForbid(false);
          isPractice && setPracticeText(AMPracticeText.allowRVM);
        } else if (
          currDanger &&
          currDanger.start <= currProgress &&
          currDanger.end > currProgress
        ) {
          setRvmForbid(true);
          isPractice && setPracticeText(AMPracticeText.disallowRVM);
        } else if (currDanger && currDanger.end < currProgress) {
          setRvmForbid(false);
          isPractice && setPracticeText(AMPracticeText.allowRVM);
          const nextDanger = forbiddenIntervals[idx + 1];
          if (nextDanger && nextDanger.start < currProgress) {
            idx++;
          }
        } else {
          setRvmForbid(false);
          isPractice && setPracticeText(AMPracticeText.allowRVM);
        }
      }, 100),
    );
  }

  function submitGlanceOnly() {
    setEnded(false);

    const pid = `users/${location.state.participantId}`;
    const tid = `tasks/${activityState.taskId}`;
    const mid = `modules/${activityState.moduleId}`;
    const sid = `scenarios/${activityState.scenarioId}`;
    const aid = `activities/${activityState.activityId}`;

    axios
      .put(
        `${ServerUrl}/${pid}/${tid}/${mid}/${sid}/${aid}/add-answer/`,
        {
          rearviewNotAllowedResponses: rvmIntervals,
          userGridResponse: [],
          timeStarted: location.state.timeStarted,
          timeEnded: Date.now(),
        },
        {
          withCredentials: true,
        },
      )
      .then((res) => {
        const activity = res.data.data;

        setIsCompleted(activity.isCompleted);
        setHasCorrectAnswer(activity.hasCorrectAnswer);

        // // not required as error already set before calling this function
        // if (
        //   activity.lastCorrectIndex !==
        //   activity.responseStartTimes.length - 1
        // ) {
        //   setActivityError(GlanceErr[activity.userGlanceStates[0]]);
        //   addIncorrectResponse();
        // }
      })
      .catch((err) => console.log(err));
  }

  function checkGridSelections() {
    const sortedGridAnswers = [...gridAnswers].sort();
    const sortedGridSelections = [...gridSelections].sort();

    const areEqual =
      sortedGridAnswers.length === sortedGridSelections.length &&
      sortedGridAnswers.every(
        (value, index) => value === sortedGridSelections[index],
      );
    return areEqual;
  }

  async function handleSubmit() {
    setIsGridAttempted(true);
    setEnded(false); // as initialize sets it to true later and so, first useEffect is run
    setAllowReset(false);

    const pid = `users/${location.state.participantId}`;
    const tid = `tasks/${activityState.taskId}`;
    const mid = `modules/${activityState.moduleId}`;
    const sid = `scenarios/${activityState.scenarioId}`;
    const aid = `activities/${activityState.activityId}`;

    if (
      !activityState.practiceMode ||
      (activityState.practiceMode && checkGridSelections())
    ) {
      setTopViewUpNext(true);

      await axios
        .put(
          `${ServerUrl}/${pid}/${tid}/${mid}/${sid}/${aid}/add-answer/`,
          {
            rearviewNotAllowedResponses: rvmIntervals,
            userGridResponse: gridSelections,
            timeStarted: location.state.timeStarted,
            timeEnded: Date.now(),
          },
          {
            withCredentials: true,
          },
        )
        .then((res) => {
          // determine glance correctness
          const activity = res.data.data;
          setIsCompleted(activity.isCompleted);
          setHasCorrectAnswer(activity.hasCorrectAnswer);

          // if (
          //   activity.lastCorrectIndex !==
          //   activity.responseStartTimes.length - 1
          // ) {
          //   // glance err has been shown before grid attempt, so will not be shown again
          //   setActivityError(GlanceErr[activity.userGlanceStates[0]]);
          // }
          if (checkGridSelections()) {
            addCorrectResponse();
          } else {
            addIncorrectResponse();
          }
        })
        .catch((err) => console.log(err));
    } else {
      // saves an unecessary call to backend to check for glance correctness
      addIncorrectResponse();
    }
  }

  return (
    <PlayerWindow
      className={[rvmShown && "unfocused", showQuestion && "question"]}
      src={topView ? topViewVid : activityVid}
      skillType="AM"
      animated
      autoStart={topView}
      loop={topView}
      playState={[playing, setPlaying]}
      resetState={[retried, setRetried]}
      hideFullscreen={rvmShown}
      onPlay={() => {
        if (topView) return;
        setMirrorDisabled(false);
        const time = Date.now();
        setStartTime(time);
        setActivityError(null);
        addNullResponse(); // for avatar
        monitorGlance(time);
        setIsGlanceAttempted(false);
        setIsGlanceCompletedForPractice(false);
        setIsGridAttempted(false);
      }}
      onPause={() => {
        // setMirrorDisabled(true);
        if (mirrorView) {
          setChecking(true);
        }
        setMirrorView(false);
        clearInterval(dangerAlert);
        setRvmForbid(false);
      }}
      onRetryLoad={importAssets}
      onEnded={() => setEnded(true)}
      ref={ref}
    >
      <div className="rvm-frame">
        <Tooltip
          className="view-tooltip"
          content="Eyes on the road!"
          disabled={!rvmForbid}
          placement="top"
          appendTo="parent"
          zIndex={0}
          popperOptions={{
            modifiers: [
              {
                name: "preventOverflow",
                options: {
                  boundary: document.querySelector(".scenario-window"),
                },
              },
            ],
          }}
        >
          <IconAlertTriangleFilled
            className="danger-alert"
            style={{
              opacity: rvmForbid ? "100%" : 0,
              display: responses.incorrect < 1 && "none",
            }}
            stroke={2}
            focusable
          />
        </Tooltip>
        <div
          className={clsx(
            "rvm",
            rvmShown && "visible",
            showQuestion && "rvm-question",
          )}
        >
          {rvmAlert && (
            <Tooltip
              className="alert-tooltip"
              disabled={!rvmAlert}
              content={rvmAlert}
              placement="left"
            >
              <div className="rvm-alert">
                <IconAlertCircleFilled />
              </div>
            </Tooltip>
          )}
          <SVG
            className="mirror"
            src={`${isMobile ? "mini-" : ""}rear-view-mirror`}
          />
          <div className="rvm-grid">
            {populateGrid()}
            <img
              src={rearViewBlankImg}
              style={{ display: (!showQuestion || isGridAttempted) && "none" }}
            />
            <img
              src={rearViewImg}
              style={{ display: showQuestion && !isGridAttempted && "none" }}
            />
          </div>
        </div>
      </div>
    </PlayerWindow>
  );
});

/** @type {ForwardRef<PlayerWindowProps, RefType<Card>>} */
const PlayerWindow = forwardRef((props, ref) => {
  const {
    src,
    skillType,
    className,
    draggable,
    autoStart,
    playState,
    resetState,
    animated,
    loop,
    onClick,
    onDrag,
    onDragStart,
    onDragEnd,
    onPause,
    onPlay,
    onEnded,
    hideFullscreen,
    seekable,
    onRetryLoad,
    styles,
    children,
  } = props;

  const [resetPlay, setResetPlay] = resetState || useState(false);
  const [playing, setPlaying] = playState || useState(autoStart);
  const [mediaPlayer, setMediaPlayer] = useState(null);
  const [mediaType, setMediaType] = useState("");
  const [duration, setDuration] = useState(0);
  const [retryBtn, setRetryBtn] = useState(null);
  const retryTimeout = useRef(null);

  const { states, setStates } = useContext(ScenarioInfo);

  const { scenarioWindow, playerLoading } = states;
  const { setPlayerLoading, setScenarioWindow } = setStates;

  // prettier-ignore
  useImperativeHandle(ref, () => {
    return scenarioWindow;
  }, [scenarioWindow]);

  useEffect(() => {
    if (!retryBtn) return;
    if (!playerLoading) {
      clearTimeout(retryTimeout.current);
      hideRetryBtn();
      return;
    }
    retryTimeout.current = setTimeout(() => {
      setPlayerLoading((loading) => {
        if (loading) showRetryBtn();
        return loading;
      });
    }, 2000);
  }, [playerLoading, retryBtn]);

  useEffect(() => {
    setPlayerLoading(true);
    if (!src) {
      setMediaType(null);
      return;
    }
    const extension = src.split(".").pop();
    setMediaType(extension);
  }, [src]);

  useEffect(() => {
    if (!mediaPlayer || duration < 0.5) return;
    if (playing) {
      if (!mediaPlayer.paused) return;
      mediaPlayer.play();
    } else {
      mediaPlayer.pause();
    }
  }, [playing, duration]);

  useEffect(() => {
    if (!mediaPlayer) return;
    if (mediaPlayer.currentTime === 0) {
      // setResetPlay(false);
    } else if (resetPlay) {
      mediaPlayer.currentTime = 0;
    }
  }, [resetPlay]);

  async function toggleFullscreen() {
    if (document.fullscreenElement !== null) {
      await document.exitFullscreen?.();
      await document.webkitExitFullscreen?.();
    } else {
      await document.documentElement.requestFullscreen?.();
      await document.documentElement.webkitRequestFullscreen?.();
    }
  }

  function hideRetryBtn() {
    if (!retryBtn) return;
    retryBtn.classList.remove("shown");
  }

  function showRetryBtn() {
    if (!retryBtn) return;
    retryBtn.classList.add("shown");
  }

  return (
    <div className="window-container">
      <Card
        className={clsx(
          "scenario-window",
          className,
          playing && "playing",
          playerLoading && "loading",
        )}
        bgColor="var(--scenario-window-bg)"
        onClick={onClick}
        onDrag={onDrag}
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
        draggable={src && draggable}
        styles={{
          ...styles,
          cursor: (animated || playerLoading) && "auto",
        }}
        ref={setScenarioWindow}
      >
        {document.fullscreenEnabled && (
          <Button
            className="toggle-fullscreen"
            variant="dark"
            fontSize="1rem"
            depth="0.5rem"
            bgColor="var(--complete-color)"
            icon={
              document.fullscreenElement !== null ? (
                <IconMinimize stroke={3.2} />
              ) : (
                <IconMaximize stroke={3.2} />
              )
            }
            onClick={toggleFullscreen}
            styles={{
              pointerEvents: hideFullscreen && "none",
              opacity: hideFullscreen ? 0 : 1,
            }}
            tippy={{
              content:
                document.fullscreenElement !== null
                  ? "Exit Fullscreen"
                  : "Fullscreen",
              placement: "right",
            }}
          />
        )}
        {mediaType === "png" && (
          <img
            className="media-player"
            src={src}
            alt="scenario image"
            draggable={false}
            onLoad={() => setPlayerLoading(false)}
          />
        )}
        {mediaType === "mp4" && (
          <video
            className="media-player"
            name="media"
            ref={setMediaPlayer}
            src={src}
            onPlay={onPlay}
            onPause={onPause}
            autoPlay
            loop={loop}
            controls={false}
            playsInline
            onLoadedData={(data) => {
              const video = data.target;
              setDuration(video.duration);
              if (!autoStart) video.pause();
              setPlayerLoading(false);
            }}
            onEnded={(event) => {
              onEnded?.(event);
              if (!loop) setPlaying(false);
            }}
            onDragStart={(event) => event.preventDefault()}
            muted
          >
            <source src={src} type="video/mp4" />
          </video>
        )}
        <div className="loading-ctrls">
          <IconLoader2 className="player loader" style={{ opacity: 0 }} />
          <Button
            className="retry"
            label="Retry"
            icon={<IconReload stroke={2.6} />}
            fontSize="1.5rem"
            variant="dark"
            bgColor="var(--default-card-bg-color)"
            styles={{ display: playerLoading ? "flex" : "none" }}
            onClick={onRetryLoad}
            ref={setRetryBtn}
          />
        </div>
        {children}
      </Card>
    </div>
  );
});

/**
 * @param {{
 *   idx: number;
 *   disabled: boolean;
 *   rightInputAnswer: boolean;
 *   wrongInputAnswer: boolean;
 *   missedAnswer: boolean;
 *   interactive: boolean;
 * }} props
 */
function RVMGridElement(props) {
  const {
    idx,
    disabled,
    rightInputAnswer,
    wrongInputAnswer,
    missedAnswer,
    interactive,
  } = props;

  const [hover, setHover] = useState(false);
  const [selected, setSelected] = useState(false);

  const { states, setStates } = useContext(ScenarioInfo);
  const { canHover } = useContext(DisplayInfo);

  const { gridSelections, isGridAttempted, responses } = states;
  const { setGridSelections } = setStates;

  useEffect(() => {
    // run when grid is attempted (right or wrong - responses keeps track of attempts)
    isGridAttempted && disabled && setSelected(false);
  }, [isGridAttempted, responses]);

  function handleSelect(event) {
    const section = event.target;
    const sectionIdx = parseInt(section.getAttribute("idx"));
    if (isNaN(sectionIdx)) return;

    const newSelections = [...gridSelections];
    const idx = newSelections.indexOf(sectionIdx);
    if (idx >= 0) {
      setSelected(false);
      newSelections.splice(idx, 1);
    } else {
      setSelected(true);
      newSelections.push(sectionIdx);
    }
    setGridSelections([...newSelections]);
  }

  function toggleHover(hover = false) {
    if (disabled || !canHover) {
      setHover(false);
      return;
    }
    setHover(hover);
  }

  return (
    <span
      idx={idx}
      className={clsx(
        "rvm-section",
        !disabled && hover && "hover",
        !disabled && selected && "selected",
        interactive && rightInputAnswer && "rightInputAnswer",
        interactive && wrongInputAnswer && "wrongInputAnswer",
        interactive && missedAnswer && "missedAnswer",
      )}
      onDragStart={(event) => event.preventDefault()}
      onClick={!disabled ? handleSelect : undefined}
      onPointerEnter={() => toggleHover(true)}
      onPointerOut={() => toggleHover()}
    >
      {!disabled &&
        interactive &&
        (hover && selected ? (
          <IconMinus stroke={3.4} />
        ) : hover ? (
          <IconPlus stroke={3.2} />
        ) : (
          selected && <IconCheck stroke={3.1} />
        ))}
    </span>
  );
}

export { ActivityAM, ActivityHA };

