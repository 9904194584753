import Tooltip from "@src/components/Tooltip";
import { IconTool } from "@tabler/icons-react";
import axios from "axios";
import clsx from "clsx";
import { useContext, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { ServerUrl, pause } from "../Utils";
import { ModalContext } from "../contexts/Contexts";
import { Button } from "./Button";
import ChangePasswordForm from "./ChangePasswordForm";
import FeedbackForm from "./FeedbackForm";
import Modal, { swapContent } from "./Modal";
import ProfilePhoto from "./ProfilePhoto";
import ResearcherTools from "./ResearcherTools";

/**
 * @param {{
 *   openState: UseState<boolean>;
 *   userInfo: {};
 * }} props
 */
const ProfileModal = (props) => {
  const { openState, userInfo } = props;
  const [isOpen, setIsOpen] = openState;
  const [changePwOpen, setChangePwOpen] = useState(false);
  const [feedbackOpen, setFeedbackOpen] = useState(false);

  const [optsShown, setOptsShown] = useState(false);
  const [toolsOpen, setToolsOpen] = useState(false);

  const navigate = useNavigate();

  function handleChangePw(modalContent) {
    swapContent(modalContent, async () => {
      await pause(150);
      setChangePwOpen(true);
      await pause(100);
    });
  }

  function handleOpenTools(modalContent) {
    swapContent(modalContent, async () => {
      await pause(100);
      setToolsOpen(true);
      await pause(100);
    });
  }

  async function handleHelp(modalContent) {
    swapContent(modalContent, async () => {
      await pause(150);
      setFeedbackOpen(true);
      await pause(100);
    });
  }

  async function handleSignOut() {
    await axios
      .post(`${ServerUrl}/users/logout`, {}, { withCredentials: true })
      .catch();
    navigate("/home");
  }

  async function afterClose() {
    setOptsShown(false);
    setChangePwOpen(false);
    setFeedbackOpen(false);
    setToolsOpen(false);
  }

  return (
    <Modal
      className="profile-modal"
      title={
        changePwOpen
          ? "Change Your Password"
          : feedbackOpen
            ? "Help & Feedback"
            : toolsOpen
              ? "Researcher Tools"
              : "Your Account"
      }
      contentLabel="Profile Modal"
      isOpen={isOpen}
      close={() => setIsOpen(false)}
      customWidth="25rem"
      afterClose={afterClose}
    >
      <Helmet>
        <title>Profile | Dashboard</title>
      </Helmet>
      <ProfileSummary
        visible={!changePwOpen && !toolsOpen && !feedbackOpen}
        userInfo={userInfo}
        optsShownState={[optsShown, setOptsShown]}
        onChangePW={handleChangePw}
        onOpenTools={handleOpenTools}
        onOpenHelp={handleHelp}
        onSignOut={handleSignOut}
      />
      <ChangePasswordForm
        isOpen={changePwOpen}
        setIsOpen={setChangePwOpen}
        userInfo={userInfo}
      />
      <ResearcherTools
        isOpen={toolsOpen}
        setIsOpen={setToolsOpen}
        userInfo={userInfo}
      />
      <FeedbackForm
        modalState={[isOpen, setIsOpen]}
        formOpenState={[feedbackOpen, setFeedbackOpen]}
        fromProfile
      />
    </Modal>
  );
};

/**
 * @param {{
 *   visible: boolean;
 *   userInfo: {};
 *   optsShownState: UseState<boolean>;
 *   onChangePW: function;
 *   onOpenTools: function;
 *   onOpenHelp: function;
 *   onSignOut: function;
 * }} props
 */
const ProfileSummary = (props) => {
  const { visible, optsShownState, userInfo, ...callbacks } = props;
  const { onChangePW, onOpenTools, onOpenHelp, onSignOut } = callbacks;
  const { contentElement } = useContext(ModalContext);

  const [optsShown, setOptsShown] = optsShownState;
  const [loading, setLoading] = useState(false);

  const firstName = (userInfo && userInfo.firstName) || "Anonymous";
  const lastName = (userInfo && userInfo.lastName) || "User";
  const email = (userInfo && userInfo.email) || "anon@email.com";
  const role = (userInfo && userInfo.role) || "EXPERIMENT";
  const formattedRole = role[0] + role.slice(1).toLowerCase();

  /** @param {HTMLDivElement | null} optsGroup */
  async function optionsPullDown(optsGroup) {
    if (loading || !optsGroup || optsShown) return;
    contentElement.style.setProperty("--scrollbar-opacity", 0);

    const height = optsGroup.scrollHeight;
    optsGroup.style.justifyContent = "end";
    optsGroup.style.setProperty("--max-height", `${height}px`);
    await pause(300);

    optsGroup.classList.add("opening");
    await pause(300);

    setOptsShown(true);
    setLoading(false);
    await pause(10);

    optsGroup.classList.remove("opening");
    contentElement.style.setProperty("--scrollbar-opacity", "60%");
  }

  return (
    visible && (
      <>
        <div className="profile-summary">
          <ProfilePhoto source="https://www.w3schools.com/howto/img_avatar.png" />
          <div className="userinfo">
            <InfoTooltip content={`${firstName} ${lastName}`}>
              <header className="name">{`${firstName} ${lastName}`}</header>
            </InfoTooltip>
            <InfoTooltip content={email}>
              <p className="email">{email}</p>
            </InfoTooltip>
            <p className="role">{/* {formattedRole} */}</p>
          </div>
        </div>
        <div
          className={clsx("btn-group", !optsShown && "hidden")}
          ref={(ref) => contentElement && ref && optionsPullDown(ref)}
        >
          <div className="separator" />
          {role === "RESEARCHER" && (
            <Button
              className="research-tools"
              variant="secondary"
              icon={<IconTool />}
              label="Researcher Tools"
              styles={{ width: "100%" }}
              onClick={() => onOpenTools(contentElement)}
            />
          )}
          <Button
            label="Change Password"
            variant="secondary"
            styles={{ width: "100%" }}
            onClick={() => onChangePW(contentElement)}
          />
          <Button
            label="Help & Feedback"
            variant="secondary"
            styles={{ width: "100%" }}
            onClick={() => onOpenHelp(contentElement)}
          />
          <Button
            label="Sign Out"
            styles={{ width: "100%" }}
            onClick={() => onSignOut(contentElement)}
          />
        </div>
      </>
    )
  );
};

/** @param {import("@tippyjs/react").TippyProps} props */
export const InfoTooltip = (props) => {
  const { overlayElement } = useContext(ModalContext);
  const delayRef = useRef(null);

  const { className, ...otherProps } = props;

  return (
    <Tooltip
      className={className || "info-tooltip"}
      appendTo={overlayElement}
      placement="right"
      delay={[500, 0]}
      trigger="mouseenter click"
      onShow={(inst) => {
        delayRef.current = setTimeout(() => {
          if (!inst.state.isDestroyed) inst.hide();
        }, 3000);
      }}
      onHide={() => clearTimeout(delayRef.current)}
      popperOptions={{
        modifiers: [
          {
            name: "flip",
            options: {
              fallbackPlacements: ["bottom"],
              boundary: overlayElement || document.body,
            },
          },
        ],
      }}
      {...otherProps}
    />
  );
};

export default ProfileModal;
